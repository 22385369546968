<template>
  <div>
    <vs-popup class="sm:popup-w-60" title="Pengajuan Pembayaran Termin" :active="isActive" v-on:update:active="emitModalIsActive">
      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Termin yang diajukan</label>
            <vs-input class="w-full" :value="termin ? 'Termin ' + termin.no_termin : ''" readonly/>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Nilai Termin</label>
            <v-money class="w-full" :value="termin ? termin.balance : null" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3 mt-base">
          <div class="vx-col w-full">
            <div class="con-vs-alert con-vs-alert-primary con-icon">
              <div class="flex justify-between">
                <div class="vs-alert con-icon">
                  <i class="vs-icon notranslate icon-scale icon-alert feather icon-info null"></i>
                  Silahkan upload file pendukung pengajuan pembayaran termin. Contoh: invoice, dll.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <div class="flex">
              <vs-button color="success" size="small" icon-pack="feather" icon="icon-plus" @click="addRow" class="py-2 px-3 mr-2">Add</vs-button>
            </div>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <vs-table :data="data.files" stripe class="fix-table-input-background">
              <template slot="thead">
                <vs-th class="whitespace-no-wrap">Action</vs-th>
                <vs-th class="whitespace-no-wrap">#</vs-th>
                <vs-th class="whitespace-no-wrap">Nama File</vs-th>
                <vs-th class="whitespace-no-wrap">File</vs-th>
              </template>
              <template slot-scope="{data}">
                <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
                  <vs-td>
                    <vs-button color="danger" class="py-2 px-3" type="filled" icon-pack="feather" icon="icon-x" size="small" @click="removeRow(item.uuid)"/>
                  </vs-td>
                  <vs-td>{{ index + 1 }}</vs-td>
                  <vs-td>
                    <vs-input v-model="item.nama" type="text"/>
                  </vs-td>
                  <vs-td>
                    <vs-input class="w-full" type="file" accept=".jpeg,.jpg,.png,.pdf" v-model="item.fileTmp" @change="item.file = $event.target.files[0]"/>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>

        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="claimPayment">
              <span v-if="isLoading" class="animate-pulse">Mengajukan...</span>
              <span v-if="!isLoading">Claim</span>
            </vs-button>
          </div>
        </div>

      </div>
    </vs-popup>
  </div>
</template>

<script>
import SpkRequestPaymentRepository from '@/repositories/proyek/spk-request-payment-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import VMoney from '@/views/components/v-money/VMoney'
import { convertToFormData } from '@/utilities/common/global-methods'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'

export default {
  name: 'ClaimPayment',
  props: {
    isActive: { required: true, type: Boolean },
    termin: { required: true, type: Object }
  },
  components: {
    VMoney,
    ValidationErrors
  },
  data () {
    return {
      isLoading: false,
      errors: null,
      data: {
        files: [
          { uuid: 1 }
        ]
      }
    }
  },
  methods: {
    claimPayment () {
      this.errors = null
      this.isLoading = true

      const params = convertToFormData({
        id: this.termin.id,
        files: this.data.files
      })
      SpkRequestPaymentRepository.claimPayment(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    addRow () {
      const row = { uuid: uuid() }
      this.data.files.push(row)
    },

    removeRow (uuid) {
      const index = _.findIndex(this.data.files, item => item.uuid === uuid)
      this.data.files.splice(index, 1)
    },

    onSuccess () {
      this.notifySuccess('Berhasil mengajukan pembayaran.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, [])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
